<template>
	<div class="box" v-loading="loginflag" element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.5)" element-loading-text="正在退出登录">
		<!-- 账号信息弹框 -->
		<div class="zh-tk" v-show="flag">
			<div class="zh-bg" @click="zhclose"></div>
			<!-- renli -->
			<div class="zh-box renren">
				<div class="renedit"><span>修改</span></div>
				<div class="ren-box">
					<div class="ren-item">
						<label for="zong">工厂总人数</label>
						<input type="text" id="zong" v-model="r_zong" :placeholder="peoplezy.zong" />
					</div>
					<div class="ren-item">
						<label for="zong">在岗</label>
						<input type="text" id="zong" v-model="r_zaigang" :placeholder="peoplezy.zaigang" />
					</div>
					<div class="ren-item">
						<label for="zong">今日值班</label>
						<input type="text" id="zong" v-model="r_zhiban" :placeholder="peoplezy.zhiban" />
					</div>
					<div class="ren-item">
						<label for="zong">今日休班</label>
						<input type="text" id="zong" v-model="r_xiuban" :placeholder="peoplezy.xiuban" />
					</div>
				</div>
				<div class="ren-btn">
					<div @click.stop="zhclose">取消</div>
					<div @click="qredit">确认修改</div>
				</div>
			</div>
			<!-- zhanghu -->
			<div class="zh-box" v-show="zhflag">
				<img src="../../assets/images/yh-close.png" class="zh-close" alt="" @click.stop="zhclose" />
				<section class="zh-con">
					<!-- 左侧菜单栏 -->
					<div class="zh-menu">
						<div class="zh-menu-item" @click.stop="zhtap(1)">
							<img src="../../assets/images/yh-xuanzhong.png" class="zh-xuan-tu" alt=""
								v-show="zhind == 1" />
							<span :class="zhind == 1 ? 'zh-xuan' : ''">账户信息</span>
						</div>
						<div class="zh-menu-item" @click.stop="zhtap(2)">
							<img src="../../assets/images/yh-xuanzhong.png" class="zh-xuan-tu" alt=""
								v-show="zhind == 2" />
							<span :class="zhind == 2 ? 'zh-xuan' : ''">分配子账号</span>
						</div>
						<div class="zh-menu-item" @click.stop="loginout()"><span>退出登录</span></div>
					</div>
					<div class="zh-r" v-if="zhind == 1">
						<div class="zh-name">
							<span class="zh-name-l">用户名</span>
							<div class="zh-name-c">
								<img src="../../assets/images/zh-bg.png" alt="" />
								<span>曲阳石料厂总后台</span>
							</div>
							<span class="zh-edit">修改</span>
						</div>
						<div class="zh-password">
							<span class="zh-name-l">密码</span>
							<div class="zh-name-c">
								<img src="../../assets/images/zh-bg.png" alt="" />
								<span>******</span>
							</div>
							<span class="zh-edit">修改</span>
						</div>
					</div>
					<!-- 分配子账号 -->
					<div class="zh-fp" v-if="zhind == 2">
						<div class="zh-fp-titlr">子账号列表</div>
						<div class="zh-fp-b">
							<div class="zh-fp-t">
								<div class="zh-fp-t-item">
									<span>用户名</span>
									<input type="text" />
								</div>
								<div class="zh-fp-t-item">
									<span>密码</span>
									<input type="password" />
								</div>
								<span class="zhaddbtn">增加此账号</span>
							</div>
							<div class="zh-table-title">
								<span>登录名</span>
								<span>密码</span>
								<span>状态</span>
							</div>
							<div class="zh-table-box">
								<div class="zh-table-item">
									<span>曲阳石料厂分账号一</span>
									<span>598969</span>
									<div class="zh-tab-cz">
										<img src="../../assets/images/yh-qiyong.png" v-show="czind == 0" alt="" />
										<img src="../../assets/images/yh-jinyong.png" v-show="czind == 1" alt="" />
										<span class="zh-tab-cz-on" v-show="czind == 1">禁用</span>
										<span v-show="czind == 0">启用</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
		<!-- 头部 -->
		<div class="header">
			<headers :sign="sign"></headers>
			<div class="user-zh" @mouseenter="huaru" @mouseleave="huachu">
				<div class="user-name" @click="zhshow">
					<img src="../../assets/images/wode.png" />
					<span>账户名称</span>
				</div>
				<div class="out-login" v-show="l_flag" @click="loginout">退出登录</div>
			</div>
		</div>
		<!-- 内容 -->
		<div class="con">
			<div class="con-left">
				<div class="con-left-top">
					<div class="con-l" v-if="dibangD">
						<div class="con-l-title" @click="todetail('productionline')">
							<span>生产线数据</span>
							<img src="../../assets/images/jin.png" alt="" />
						</div>
						<div class="scx">
							<div class="rkclsl">
								<progress :value="leijinum" :max="leijinum"></progress>
								<div>
									<span>机制砂数量（累计）</span>
									<p>
										{{ leijinum }}
										<span>T</span>
									</p>
								</div>
							</div>
							<div class="clscsl rkclsl">
								<progress :value="dibangD.ri.shengchan" :max="leijinum"></progress>
								<div>
									<span>今日生产数量</span>
									<p>
										{{ parseFloat(dibangD.ri.shengchan).toFixed(2) }}
										<span>T</span>
									</p>
								</div>
								<div class="xl">
									<span>实时生产效率</span>
									<p>
										{{ parseFloat(dibangD.ri.xiaolv).toFixed(2) }}
										<span>T/H</span>
									</p>
								</div>
							</div>
							<div class="clscsl rkclsl">
								<progress :value="dibangD.zhou.shengchan" :max="leijinum"></progress>
								<div>
									<span>本周生产数量</span>
									<p>
										{{ parseFloat(dibangD.zhou.shengchan).toFixed(2) }}
										<span>T</span>
									</p>
								</div>
							</div>

							<div class="clscsl rkclsl">
								<progress :value="parseFloat(dibangD.yue.shengchan)" :max="leijinum"></progress>
								<div>
									<span>本月生产数量</span>
									<p>
										{{parseFloat(dibangD.yue.shengchan).toFixed(2)}}
										<span>T</span>
									</p>
								</div>
							</div>
						</div>
						<!-- 出入库数据 -->
						<div class="con-l-title crk-title">
							<div class=" crk-title-l" @click="todetail('receiptIssue')">
								<span>出入库数据</span>
								<img src="../../assets/images/jin.png" alt="" />
							</div>
							<div class="crk-tab">
								<span :class="crkindex === 0 ? 'on' : ''" @click="crktab(0)">今日</span>
								<span :class="crkindex === 1 ? 'on' : ''" @click="crktab(1)">本月</span>
								<span :class="crkindex === 2 ? 'on' : ''" @click="crktab(2)">今年</span>
							</div>
						</div>
						<div class="crk-tabbox">
							<div class="crkdata">
								<div class="crkdata-top">
									<div class="crkdata-top-item">
										<span>{{drkArr[crkindex]}}生产总量</span>
										<p>
											{{dibang.val1}}
											<span>T</span>
										</p>
									</div>
									<div class="crkdata-top-item">
										<span>剩余库存</span>
										<p>
											{{dibang.val2}}
											<span>T</span>
										</p>
									</div>
								</div>
							</div>
							<!-- 今日成品料出场 -->
							<div class="jrcpl">
								<div id="pieone" ref="pieone"></div>
								<div class="jrcpl-list">
									<div class="jrcpl-list-item">
										<div class="jrcpl-item">
											<div class="jrcpl-item-con">
												<div></div>
												<span>{{ config2.data[0].name }}</span>
											</div>
											<p>
												{{ config2.data[0].value }}
												<span>T</span>
											</p>
										</div>
										<div class="dian"></div>
									</div>
									<div class="jrcpl-list-item">
										<div class="jrcpl-item">
											<div class="jrcpl-item-con">
												<div class="cpl2"></div>
												<span>{{ config2.data[1].name }}</span>
											</div>
											<p>
												{{ config2.data[1].value }}
												<span>T</span>
											</p>
										</div>
										<div class="dian"></div>
									</div>
									<div class="jrcpl-list-item">
										<div class="jrcpl-item">
											<div class="jrcpl-item-con">
												<div class="cpl3"></div>
												<span>{{ config2.data[2].name }}</span>
											</div>
											<p>
												{{ config2.data[2].value }}
												<span>T</span>
											</p>
										</div>
										<div class="dian"></div>
									</div>
								</div>
							</div>

							<div class="crkdata">
								<div class="crkdata-top">
									<div class="crkdata-top-item">
										<span>{{drkArr[crkindex]}}入库总量</span>
										<p>
											{{maosha.val1}}
											<span>T</span>
										</p>
									</div>
									<div class="crkdata-top-item">
										<span>进场车次</span>
										<p>
											{{maosha.val2}}
											<span>次</span>
										</p>
									</div>
								</div>
							</div>
							<div class="jrcpl">
								<div id="pieone2" ref="pieone2"></div>
								<!--  -->
								<!-- <dv-active-ring-chart :config="config2" style="width:280px;height:280px" /> -->
								<div class="jrcpl-list">
									<div class="jrcpl-list-item">
										<div class="jrcpl-item">
											<div class="jrcpl-item-con">
												<div></div>
												<span>进场数量</span>
											</div>
											<p>
												{{maosha.val3}}
												<span>T</span>
											</p>
										</div>
										<div class="dian"></div>
									</div>
									<div class="jrcpl-list-item">
										<div class="jrcpl-item">
											<div class="jrcpl-item-con">
												<div></div>
												<span>已加工量</span>
											</div>
											<p>
												{{maosha.val4}}
												<span>T</span>
											</p>
										</div>
										<div class="dian"></div>
									</div>
									<div class="jrcpl-list-item">
										<div class="jrcpl-item">
											<div class="jrcpl-item-con">
												<div></div>
												<span>剩余库存</span>
											</div>
											<p>
												{{maosha.val5}}
												<span>T</span>
											</p>
										</div>
										<div class="dian"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 中间部分 -->
					<div class="con-center">
						<section class="con-center-tt">
							<div class="con-tab">
								<span>选择分屏:</span>
								<span :class="pind == 0 ? 'onS' : ''" @click="ping(0)">2屏</span>
								<span :class="pind == 1 ? 'onS' : ''" @click="ping(1)">4屏</span>
								<span :class="pind == 2 ? 'onS' : ''" @click="ping(2)">9屏</span>
								<!-- <span :class="pind == 3 ? 'onS' : ''" @click="ping(3)">12屏</span> -->
								<!-- <span :class="pind == 4 ? 'onS' : ''" @click="ping(4)">16屏</span> -->
							</div>
							<div class="con-jiankong">
								<div class="con-center-item" :class="'sty_' + pind"
									@click="todetail('realtimejk', item.id)" v-for="item in videoList" :key="item.id">
									<img src="../../assets/images/jkg-bg.png" class="jk-bg" alt="" />
									<div class="jk">
										<live-player class="sp" live muted hide-big-play-button :aspect='aspect'
											autoplay stretch :video-url="item.url" style="width: 100%;height: 500px;"
											@error="restartPlayer" />
										<div class="con-center-item-top">
											<div>
												<img src="../../assets/images/Frame.png" alt="" />
												<span>{{ item.name }}</span>
											</div>
										</div>
										<div class="con-center-item-bottom"></div>
									</div>
								</div>
							</div>
						</section>

						<div class="block1">
							<el-pagination @current-change="currentChange" :current-page="currentPage"
								:page-size="pagesize" layout=" prev, pager, next" :total="total"></el-pagination>
						</div>
					</div>
				</div>
				<div class="con-left-buttom">
					<div class="d-flex a-center clb_tab">
						<span class="son" :class="dbindex === 0 ? 'on' : ''" @click="todetail('Weighbridge?type=0')"
							@mouseenter="dbindex=0">成品砂实时数据</span>
						<span class="son" :class="dbindex === 1 ? 'on' : ''" @click="todetail('Weighbridge?type=1')"
							@mouseenter="dbindex=1">毛砂实时数据</span>
					</div>
					<block v-if="dbindex==0">
						<div class="db-t">
							<div v-for="(name, index) in header" :key="index" class="db-t-h">{{ name }}</div>
						</div>
						<div v-for="item in dblist" :key="item.id" class="db-b">
							<div class="">
								<span>{{ item.xuhao }}</span>
								<span>{{ item.huoming }}</span>
								<span>{{ item.guige }}</span>
								<span v-if="item.type == 2">出厂</span>
								<span v-if="item.type == 1">入厂</span>
								<span>{{ item.chehao }}</span>
								<span>{{item.sh_unit}}</span>
								<span>{{ item.maozhong }}T</span>
								<span>{{ item.pizhong }}T</span>
								<span>{{ item.jingzhong }}T</span>
								<span>{{ item.gengxinren }}</span>
								<span>{{ item.fguobang }}</span>
								<span>{{ item.sguobang }}</span>
							</div>
						</div>
					</block>
					<block v-else>
						<div class="db-t">
							<div v-for="(name, index) in header2" :key="index" class="db-t-h">{{ name }}</div>
						</div>
						<div v-for="item in dblist2" :key="item.id" class="db-b">
							<div class="">
								<span>{{ item.xuhao }}</span>
								<span>{{ item.huoming }}</span>
								<span>{{ item.guige }}</span>
								<span v-if="item.type == 2">出厂</span>
								<span v-if="item.type == 1">入厂</span>
								<span>{{ item.chehao }}</span>
								<span>{{ item.maozhong }}T</span>
								<span>{{ item.pizhong }}T</span>
								<span>{{ item.jingzhong }}T</span>
								<span>{{ item.gengxinren }}</span>
								<span>{{ item.fguobang }}</span>
								<span>{{ item.sguobang }}</span>
							</div>
						</div>
					</block>
				</div>
			</div>
			<div class="con-r">
				<div class="con-l-title">
					<span>安全警告</span>
					<img src="../../assets/images/jin.png" alt="" />
				</div>
				<!-- 安全警告 -->
				<div class="aqjg">
					<img src="../../assets/images/redyuj.png" alt="" />
					<img src="../../assets/images/y-yuj.png" alt="" />
					<img src="../../assets/images/g-yj.png" alt="" />
				</div>
				<!-- 人力资源 -->
				<div class="con-l-title rlzy-title">
					<span>人力资源</span>
					<img src="../../assets/images/jin.png" alt="" />
				</div>
				<div class="rlzy-list">
					<div class="rlzy-item" @click="editrenshow">
						<div class="rlzy-l">
							<img src="../../assets/images/Group 27.png" alt="" />
							<span>工厂总人数</span>
						</div>
						<p>
							{{ peoplezy.zong }}
							<span>人</span>
						</p>
					</div>
					<div class="rlzy-item" @click="editrenshow">
						<div class="rlzy-l">
							<div></div>
							<span>在岗</span>
						</div>
						<p>
							{{ peoplezy.zaigang }}
							<span>人</span>
						</p>
					</div>
					<div class="rlzy-item" @click="editrenshow">
						<div class="rlzy-l">
							<div></div>
							<span>今日值班</span>
						</div>
						<p>
							{{ peoplezy.zhiban }}
							<span>人</span>
						</p>
					</div>
					<div class="rlzy-item" @click="editrenshow">
						<div class="rlzy-l">
							<div></div>
							<span>今日休班</span>
						</div>
						<p>
							{{ peoplezy.xiuban }}
							<span>人</span>
						</p>
					</div>
				</div>
				<!-- 电力资源 -->
				<div class="con-l-title rlzy-title " @click="todetail('power')">
					<span>电力资源</span>
					<img src="../../assets/images/jin.png" alt="" />
				</div>
				<div class="dlzy">
					<div class="dlzy-top">
						<div class="dlzy-item">
							<img src="../../assets/images/Group 2.png" alt="" />
							<div>
								<p>
									{{daydian}}
									<span>kw.h</span>
								</p>
								<span>今日耗电量</span>
							</div>
						</div>
						<div class="dlzy-item">
							<img src="../../assets/images/Group 2.png" alt="" />
							<div>
								<p>
									{{yuedian}}
									<span>kw.h</span>
								</p>
								<span>本月耗电量</span>
							</div>
						</div>
					</div>
					<div id="line"></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import LivePlayer from '@liveqing/liveplayer'
	import * as echarts from 'echarts';
	import headers from '../../components/headers.vue';
	const config2 = {
		radius: '78%',
		activeRadius: '80%',
		data: [{
				name: '生产总量',
				value: 0
			},
			{
				name: '销售总量',
				value: 0
			},
			{
				name: '剩余库存',
				value: 0
			}
		],
		digitalFlopStyle: {
			fontSize: 20
		},
		showOriginValue: true,
		lineWidth: 8,
		color: ['#159AFF', '#D0DEEE', '#66E1DF']
	};
	const crk2 = {
		radius: '78%',
		activeRadius: '80%',
		data: [{
				name: '生成总量',
				value: 300
			},
			{
				name: '销售总量',
				value: 200
			},
			{
				name: '剩余库存',
				value: 100
			}
		],
		digitalFlopStyle: {
			fontSize: 20
		},
		lineWidth: 8,
		color: ['#159AFF', '#D0DEEE', '#66E1DF']
	};
	const crk3 = {
		radius: '78%',
		activeRadius: '80%',
		data: [{
				name: '生成总量',
				value: 3600
			},
			{
				name: '销售总量',
				value: 3300
			},
			{
				name: '剩余库存',
				value: 300
			}
		],
		digitalFlopStyle: {
			fontSize: 20
		},
		lineWidth: 8,
		color: ['#159AFF', '#D0DEEE', '#66E1DF']
	};

	export default {
		components: {
			headers,
			LivePlayer
		},
		data() {
			return {
				header: ['序号', '货名', '规格', '出入标识', '车号', '收货单位', '毛重', '皮重', '地磅净重', '过磅员', '首次过磅时间',
					'二次过磅时间'
				],
				header2: ['序号', '货名', '规格', '出入标识', '车号', '毛重', '皮重', '地磅净重', '过磅员', '首次过磅时间',
					'二次过磅时间'
				],
				zshengyu: "", // 总剩余
				ridata: [], //生产数据日
				yuedata: [], //生产数据yue
				niandata: [], //生产数据nian
				zhoudata: [], //生产数据zhou
				leijinum: '', //累计生产数量
				rixlv: '', //日生产效率
				sign: 0,
				config2,
				crk2,
				crk3,
				crkindex: 0,
				flag: false,
				loginflag: false,
				renflag: false,
				peoplezy: {}, //人力资源
				daydian: "",
				yuedian: "",
				zhoudate: {},
				zhoudian: {},
				r_zong: "",
				r_zaigang: "",
				r_zhiban: "",
				r_xiuban: "",
				dblist: [], //地磅数据
				dblist2: [], //毛纱地磅数据
				time: '',
				videoUrl: '',
				videoList: [],
				videoList2: [],
				videoList3: [],
				videoList4: [],
				aspect: '5:2',
				bdmz: "",
				bdjz: "",
				shuizhong: '',
				pind: 0,
				l_flag: false, //退出登录显示
				page: 1,
				total: 0, //总条目数
				pagesize: 2, //每页显示条目个数
				currentPage: 1, //当前页数
				zhflag: false,
				zhind: 1,
				czind: 0, //账号状态0启用 1禁用
				dbindex: 0,

				drkArr: ['今日', '本月', '今年'],
				dibang: {
					'val1': '',
					'val2': ''
				},
				maosha: {
					'val1': '',
					'val2': ''
				},
				dibangD: null,
				maoshaD: null
			};
		},
		mounted() {
			this.getdblist();
			this.getrl();
			const date = new Date();
			const year = date.getFullYear();
			const month = date.getMonth() + 1;
			const day = date.getDate();
			this.time = year + '-' + month + '-' + day;
			this.getshexiang(this.pind);
			const y_time = year + '-' + month;
			let timer = setInterval(() => {
				this.getdblist();
			}, 30000);
			this.getpidaidata(y_time, year);
			this.getdian(y_time, year)
		},

		methods: {
			// 人力资源
			editrenshow() {
				this.renflag = true;
				this.flag = true;
			},
			xg() {},
			async xgdibang(item, a) {
				let data = {
					d_id: item.id,
					shuizhong: item.shuizhong,
					bdmz: item.bdmz,
					bdjz: item.bdjz
				}
				let that = this
				const res = await this.$postToken("datas/update-dibang", data)
				if (res.data.code == 200) {
					that.getdblist()
				} else {
					this.$message({
						message: res.data.msg,
						type: 'error'
					});
				}
			},
			async qredit() {
				const res = await this.$postToken("datas/renli2", {
					zong: this.r_zong,
					zaigang: this.r_zaigang,
					zhiban: this.r_zhiban,
					xiuban: this.r_xiuban
				})
				if (res.data.code == 200) {
					this.getrl()
					this.flag = false
				} else {
					this.flag = false
				}
			},
			async getrl() {
				const res = await this.$postToken('datas/renli ');
				if (res.data.code == 200) {
					// console.log(res.data.result.day);
					this.peoplezy = res.data.result.day;
					this.r_zong = this.peoplezy.zong
					this.r_zaigang = this.peoplezy.zaigang
					this.r_zhiban = this.peoplezy.zhiban
					this.r_xiuban = this.peoplezy.xiuban
				}
			},
			line(a, b) {
				var chartDom = document.getElementById('line');
				var myChart = echarts.init(chartDom);
				var option;
				option = {
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: a
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						data: b,
						type: 'line',
						areaStyle: {}
					}]
				};
				option && myChart.setOption(option);
			},
			getPie() {
				var chartDom2 = this.$refs.pieone;
				var myChart2 = echarts.init(chartDom2);
				var that = this
				var option, value1 = 0,
					value2 = 0,
					value3 = 0;
				if (that.crkindex == 0) {
					value1 = parseFloat(that.dibangD.ri.shengchan).toFixed(2)
					value2 = parseFloat(that.dibangD.ri.xiaoshou).toFixed(2)
					value3 = parseFloat(that.zshengyu).toFixed(2) > 0 ? parseFloat(that.zshengyu).toFixed(2) : 0
				} else if (that.crkindex == 1) {
					value1 = parseFloat(that.dibangD.yue.shengchan).toFixed(2)
					value2 = parseFloat(that.dibangD.yue.xiaoshou).toFixed(2)
					value3 = parseFloat(that.zshengyu).toFixed(2) > 0 ? parseFloat(that.zshengyu).toFixed(2) : 0
				} else if (that.crkindex == 2) {
					value1 = parseFloat(that.dibangD.nian.shengchan).toFixed(2)
					value2 = parseFloat(that.dibangD.nian.xiaoshou).toFixed(2)
					value3 = parseFloat(that.zshengyu).toFixed(2) > 0 ? parseFloat(that.zshengyu).toFixed(2) : 0
				}

				that.config2.data[0]['value'] = value1
				that.config2.data[1]['value'] = value2
				that.config2.data[2]['value'] = parseFloat(that.zshengyu).toFixed(2)
				option = {
					tooltip: {
						trigger: 'item',
						showContent: false,
						enterable: false
					},
					color: ['#159aff', '#d0deee', '#66e1df'],
					legend: {
						top: '5%',
						left: 'center'
					},
					series: [{
						type: 'pie',
						radius: ['60%', '70%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 4,
							borderColor: '#fff',
							borderWidth: 2
						},
						label: {
							show: false,
							position: 'center'
						},

						emphasis: {
							label: {
								show: true,
								fontSize: 18,
								fontWeight: ''
							}
						},
						labelLine: {
							show: false
						},
						data: [{
							value: value1
						}, {
							value: value2
						}, {
							value: value3
						}]
					}]
				};
				myChart2.setOption(option);
			},
			//毛砂
			getPie2() {
				var chartDom2 = this.$refs.pieone2;
				var myChart2 = echarts.init(chartDom2);
				var that = this
				var option, value1 = 0,
					value2 = 0,
					value3 = 0;
				if (that.crkindex == 0) {
					value1 = parseFloat(that.maoshaD.ri.jinchangshuliang).toFixed(2)
					value2 = 0
					value3 = 0
				} else if (that.crkindex == 1) {
					value1 = parseFloat(that.maoshaD.yue.jinchangshuliang).toFixed(2)
					value2 = 0
					value3 = 0
				} else if (that.crkindex == 2) {
					value1 = parseFloat(that.maoshaD.nian.jinchangshuliang).toFixed(2)
					value2 = 0
					value3 = 0
				}

				that.maosha.val3 = value1
				that.maosha.val4 = value2.toFixed(2)
				that.maosha.val5 = value3.toFixed(2)
				option = {
					tooltip: {
						trigger: 'item',
						showContent: false,
						enterable: false
					},
					color: ['#159aff', '#d0deee', '#66e1df'],
					legend: {
						top: '5%',
						left: 'center'
					},
					series: [{
						type: 'pie',
						radius: ['60%', '70%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 4,
							borderColor: '#fff',
							borderWidth: 2
						},
						label: {
							show: false,
							position: 'center'
						},

						emphasis: {
							label: {
								show: true,
								fontSize: 18,
								fontWeight: ''
							}
						},
						labelLine: {
							show: false
						},
						data: [{
							value: value1
						}, {
							value: value2
						}, {
							value: value3
						}]
					}]
				};
				myChart2.setOption(option);
				that.$forceUpdate()
			},
			// 电力
			async getdian(a, b) {
				const res = await this.$postToken('datas/dianli', {
					day: this.time,
					month: a,
					year: b,
				})
				if (res.data.code == 200) {
					this.daydian = Number(res.data.result.day_dian2).toFixed(2)
					this.yuedian = Number(res.data.result.yue_dian2).toFixed(2)
					let zhou = res.data.result.zhou_data
					this.zhoudate = zhou.map(item => {
						return item.date
					})
					this.zhoudian = zhou.map(item => {
						return item.data
					})
					this.line(this.zhoudate, this.zhoudian)
				}
			},

			getData() {
				var that = this
				if (this.crkindex == 0) { //日
					if (that.dibangD) {
						that.dibang.val1 = parseFloat(that.dibangD.ri.shengchan).toFixed(2)
						that.dibang.val2 = parseFloat(that.dibangD.zongshengyu).toFixed(2)
					}
					if (that.maoshaD) {
						that.maosha.val1 = parseFloat(that.maoshaD.ri.jinchangshuliang).toFixed(2)
						that.maosha.val2 = parseInt(that.maoshaD.ri.jinchangcheshu)
					}
				} else if (this.crkindex == 1) { //月
					if (that.dibangD) {
						that.dibang.val1 = that.dibangD.yue.shengchan.toFixed(2)
						that.dibang.val2 = that.dibangD.zongshengyu.toFixed(2)
					}
					if (that.maoshaD) {
						that.maosha.val1 = parseFloat(that.maoshaD.yue.jinchangshuliang).toFixed(2)
						that.maosha.val2 = parseInt(that.maoshaD.yue.jinchangcheshu)
					}
				} else { //年
					if (that.dibangD) {
						that.dibang.val1 = that.dibangD.nian.shengchan.toFixed(2)
						that.dibang.val2 = that.dibangD.zongshengyu.toFixed(2)
					}
					if (that.maoshaD) {
						that.maosha.val1 = parseFloat(that.maoshaD.nian.jinchangshuliang).toFixed(2)
						that.maosha.val2 = parseInt(that.maoshaD.nian.jinchangcheshu)
					}
				}
			},

			// 皮带秤
			async getpidaidata(a, b) {
				var that = this
				const res = await this.$postToken('datas/pidai-datas', {
					r_time: this.time,
					y_time: a,
					n_time: b
				});
				if (res.data.code == 200) {
					this.dibangD = res.data.result
					this.leijinum = res.data.result.leiji;
					this.zshengyu = parseFloat(res.data.result.zongshengyu).toFixed(2);
					this.getData() //展示数据
					setTimeout(function() {
						that.getPie() //圆环图
					}, 1000)
					// this.rixlv = Number(res.data.result.ri.xiaolv).toFixed(2);
					// config2.data[0].value = Number(res.data.result.ri.shengchan).toFixed(2);
					// config2.data[1].value = Number(res.data.result.ri.xiaoshou).toFixed(2);
					// config2.data[2].value = Number(res.data.result.zongshengyu).toFixed(2);

					// crk2.data[0].value = Number(res.data.result.yue.shengchan).toFixed(2);
					// crk2.data[1].value = Number(res.data.result.yue.xiaoshou).toFixed(2);
					// crk2.data[2].value = Number(res.data.result.zongshengyu).toFixed(2);
					// crk3.data[0].value = Number(res.data.result.nian.shengchan).toFixed(2);
					// crk3.data[1].value = Number(res.data.result.nian.xiaoshou).toFixed(2);
					// crk3.data[2].value = Number(res.data.result.zongshengyu).toFixed(2);
					// this.ridata = res.data.result.ri;
					// this.yuedata = res.data.result.yue;
					// this.niandata = res.data.result.nian;
					// this.zhoudata = Number(res.data.result.zhou.shengchan).toFixed(2);
					// this.pie1();
					// this.pie2();
					// this.pie3();
					this.$forceUpdate();
				}

				const res2 = await this.$postToken('datas/maosha-datas', {
					r_time: this.time,
					y_time: a,
					n_time: b
				});
				if (res2.data.code == 200) {
					this.maoshaD = res2.data.result
					this.getData() //展示数据
					setTimeout(function() {
						that.getPie2() //圆环图
					}, 1000)
					// this.rixlv = Number(res2.data.result.ri.xiaolv).toFixed(2);
					// config2.data[0].value = Number(res2.data.result.ri.shengchan).toFixed(2);
					// config2.data[1].value = Number(res2.data.result.ri.xiaoshou).toFixed(2);
					// config2.data[2].value = Number(res2.data.result.zongshengyu).toFixed(2);
					// this.zshengyu = Number(res2.data.result.zongshengyu).toFixed(2);

					// crk2.data[0].value = Number(res2.data.result.yue.shengchan).toFixed(2);
					// crk2.data[1].value = Number(res2.data.result.yue.xiaoshou).toFixed(2);
					// crk2.data[2].value = Number(res2.data.result.zongshengyu).toFixed(2);
					// crk3.data[0].value = Number(res2.data.result.nian.shengchan).toFixed(2);
					// crk3.data[1].value = Number(res2.data.result.nian.xiaoshou).toFixed(2);
					// crk3.data[2].value = Number(res2.data.result.zongshengyu).toFixed(2);
					// this.ridata2 = res2.data.result.ri;
					// this.yuedata2 = res2.data.result.yue;
					// this.niandata2 = res2.data.result.nian;
					// this.zhoudata2 = Number(res2.data.result.zhou.shengchan).toFixed(2);
					// this.leijinum = res2.data.result.leiji;
					// this.pie11();
					// this.pie22();
					// this.pie33();
					this.$forceUpdate();
				}
			},
			// 账户信息弹框
			zhshow() {
				this.zhflag = true;
				this.flag = true;
			},
			zhclose() {
				this.zhflag = false;
				this.flag = false;
			},
			// 获取地磅数据列表
			async getdblist() {
				let timer2;
				let res = await this.$postToken('datas/dibang-list', {
					time: '',
					start_time: '',
					end_time: '',
					page_index: 1,
					page_size: 4
				});
				if (res.data.code == 200) {
					this.dblist = res.data.result.list;
				}

				let res2 = await this.$postToken('datas/maosha-list', {
					time: '',
					start_time: '',
					end_time: '',
					page_index: 1,
					page_size: 4
				});
				if (res2.data.code == 200) {
					this.dblist2 = res2.data.result.list;
				}
			},
			// 分页
			currentChange(e) {
				// console.log(e);
				this.page = e;
				this.getshexiang(this.pind);
			},
			// 获取摄像头
			async getshexiang(e) {
				let res = await this.$postToken('datas/shexiang-data', {
					search_name: '',
					page_index: this.page,
					page_size: this.pagesize
				});

				if (res.data.code == 200) {
					// console.log(res.data);
					this.total = res.data.result.count;
					this.videoList = res.data.result.list;
					this.videoList2 = res.data.result.list.slice(0, 9);
					this.videoList3 = res.data.result.list.slice(0, 12);
					this.videoList4 = res.data.result.list.slice(0, 16);
					this.videoList5 = res.data.result.list.slice(0, 2);
					if (e == 1) {
						this.videoList = this.videoList.slice(0, 4);
					} else if (e == 2) {
						this.videoList = this.videoList2;
					} else if (e == 3) {
						this.videoList = this.videoList3;
					} else if (e == 4) {
						this.videoList = this.videoList4;
					} else if (e == 0) {
						this.videoList = this.videoList5;
					}
				}
			},

			// ping
			ping(e) {
				this.pind = e;
				if (e == 0) {
					this.aspect = '5:2';
					this.pagesize = 2;
				}
				if (e == 1) {
					this.aspect = '16:12';
					this.pagesize = 4;
				}
				if (e == 2) {
					this.aspect = '16:10';
					this.pagesize = 9;
				}
				if (e == 3) {
					this.aspect = '16:14';
				}
				if (e == 4) {
					this.aspect = '16:10';
					this.pagesize = this.total;
				}
				this.$nextTick(() => {
					this.getshexiang(e);
				});
			},
			//
			// sp error
			restartPlayer() {},

			// 去next
			todetail(url, id) {
				this.$router.push({
					path: '/' + url,
					query: {
						id: id
					}
				});
			},

			// 退出登录显示
			huaru() {
				this.l_flag = true;
			},

			huachu() {
				this.l_flag = false;
			},

			crktab(e) {
				this.crkindex = e;
				this.getData()
				this.getPie()
				this.getPie2()
			},
			// 账户切换
			zhtap(e) {
				this.zhind = e;
			},
			// 退出登录
			loginout() {
				this.loginflag = true;
				localStorage.removeItem('userinfo');
				if (localStorage.getItem('userinfo')) {} else {
					this.loginflag = false;
					this.$router.push({
						path: '/'
					});
				}
			}
		}
	};
</script>
<style scoped>
	.header {
		position: relative;
	}

	.user-zh {
		position: absolute;
		top: 36px;
		right: 36px;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
	}

	.user-name img {
		display: block;
		width: 30px;
		height: 30px;
		margin-right: 8px;
		box-sizing: border-box;
	}

	.user-name {
		display: flex;
		align-items: center;
	}

	.user-name span {
		display: block;
		width: 60px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.out-login {
		width: 94px;
		height: 30px;
		line-height: 30px;
		background-color: #022042;
		border-radius: 4px 4px 4px 4px;
		color: #159aff;
		text-align: center;
		margin-top: 7px;
		cursor: pointer;
	}

	.user-name span {
		font-size: 14px;
		color: #159aff;
	}

	/* 内容 */
	.con {
		display: flex;
		justify-content: space-between;
	}

	.con-left {
		flex: 1;
	}

	.con-left .con-left-top {
		display: flex;
		justify-content: space-between;
	}

	/* 内容左侧 */
	.con-l {
		margin-left: 36px;
		width: 400px;
		height: 100%;
	}

	.con-l-title {
		margin-top: 10px;
		height: 40px;
		font-size: 20px;
		padding: 0 0 20px 12%;
		box-sizing: border-box;
		font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
		font-weight: bold;
		color: #ffffff;
		background: url(../../assets/images/title\(1\).png) no-repeat;
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.con-l-title span {
		display: block;
		padding-bottom: 10px;
	}

	.con-l-title img {
		width: 12px;
		height: 12px;
		margin: 0 0 1% 3%;
	}

	/* 数据 */
	.scx {
		margin-bottom: 50px;
	}

	.scx progress {
		width: 100%;
		height: 10px;
		border-radius: 0;
	}

	.scx progress::-webkit-progress-bar {
		background: rgba(21, 154, 255, 0.2);
	}

	.scx progress::-webkit-progress-value {
		background: linear-gradient(90deg, #159aff 0%, #66e1df 100%);
	}

	.rkclsl div {
		width: 100%;
		display: flex;
		align-items: center;
		color: #fff;
		justify-content: space-between;
	}

	.rkclsl div span {
		font-size: 14px;
		font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
		font-weight: 500;
		color: #d0deee;
	}

	.rkclsl div p {
		font-size: 24px;
		font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
		font-weight: 500;
		color: #ffffff;
	}

	.clscsl {
		margin-top: 2%;
		margin-bottom: 10px;
	}

	.rkclsl .xl p {
		font-size: 20px;
	}

	.rkclsl .xl p span {
		font-size: 12px;
	}

	/* 出入库数据 */
	.crk-title-l {
		width: 130px;
	}

	.crk-title-l {
		display: flex;
		align-items: center;
	}

	.crk-title {
		margin-bottom: 10px;
		display: flex;
		justify-content: space-between;
	}

	.crk-tab {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.crk-tab span {
		width: 50px;
		height: 25px;
		display: block;
		border-top: 2px solid #6c8097;
		background: linear-gradient(180deg, #2d3f57 0%, rgba(108, 128, 151, 0) 100%);
		font-size: 14px;
		line-height: 25px;
		text-align: center;
		margin-left: 6px;
	}

	.crk-tab .on {
		border-top: 2px solid #159aff;
		background: linear-gradient(180deg, #094983 0%, rgba(21, 154, 255, 0) 100%);
	}

	.crkdata-top {
		display: flex;
		justify-content: space-between;
	}

	.crkdata-top-item {
		width: 165px;
		height: 68px;
		background: linear-gradient(90deg, rgba(21, 154, 255, 0) 0%, rgba(3, 26, 62) 31%, rgba(3, 26, 62) 69%, rgba(21, 154, 255, 0) 100%);
		border-left: 2px solid #159aff;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.crkdata-top-item span {
		color: #d0deee;
		font-size: 14px;
	}

	.crkdata-top-item p {
		color: #fff;
		font-weight: 600;
		font-size: 24px;
		/* width: 120px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden; */
	}

	/* 今日成品料出厂 */
	.jrcpl {
		display: flex;
		align-items: center;
	}

	.jrcpl-item .jrcpl-item-con {
		display: flex;
		align-items: center;
	}

	.jrcpl-list {
		width: 65%;
		height: 100px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.jrcpl-list-item {
		display: flex;
		align-items: flex-end;
	}

	.jrcpl-item {
		width: 100%;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid rgba(108, 128, 151, 0.5);
	}

	.jrcpl-item span {
		font-size: 14px;
		font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
		font-weight: 400;
		color: #d0deee;
	}

	.jrcpl-item p {
		font-size: 18px;
		font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
		font-weight: 500;
		color: #ffffff;
	}

	.jrcpl-item div div {
		width: 10px;
		height: 10px;
		background: #159aff;
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
		margin-right: 10px;
	}

	.dian {
		width: 4px;
		height: 4px;
		background: #6c8097;
		border-radius: 100%;
	}

	.jrcpl-item div .cpl2 {
		background: #d0deee;
	}

	.jrcpl-item div .cpl3 {
		background: #66e1df;
	}

	/* 中间内容 监控 */
	.con-center {
		margin-left: 30px;
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.con-center-tt {
		flex: 1;
		display: flex;
		flex-direction: column;
	}

	.con-center-item {
		margin-left: 0.5vw;
		margin-bottom: 10px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		overflow: hidden;
		/* background: url(../../assets/images/jk-bg.png); */
	}

	.jk-bg {
		width: 100%;
		height: 100%;
		position: absolute;
	}

	.con-center-item .jk .sp {
		display: block;
		width: 100%;
		height: 100%;
	}

	.con-center-item .jk {
		width: 94%;
		height: 90%;
		position: relative;
	}

	.red {
		background: url(../../assets/images/jkr-bg.png);
	}

	.yellow {
		background: url(../../assets/images/jky-bg.png);
	}

	.green {
		background: url(../../assets/images/jkg-bg.png);
	}

	.con-center-item-top {
		padding-right: 10px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.con-center-item .jk .con-center-item-top {
		position: absolute;
		top: 0px;
		z-index: 9;
		width: 100%;
		height: 10%;
		padding: 10px 0;
		/* line-height: 40px; */
		border-radius: 0px 0px 0px 0px;
	}

	.con-center-item .jk .con-center-item-top div {
		padding: 0 10px;
		display: flex;
		align-items: center;
		color: #66e1df;
		font-size: 14px;
		width: 100%;
		justify-content: flex-end;
	}

	.con-center-item .jk .con-center-item-top img {
		width: 18px;
		height: 18px;
	}

	.con-center-item .jk .con-center-item-bottom {
		position: absolute;
		bottom: 10px;
		right: 10px;
		color: #fff;
		font-size: 12px;
	}

	.con-r {
		width: 370px;
		margin-right: 36px;
		box-sizing: border-box;
	}

	/* 安全预警 */
	.aqjg {
		width: 100%;
		display: flex;
		justify-content: space-around;
		margin-bottom: 40px;
	}

	.aqjg img {
		width: 76px;
		height: 92px;
	}

	/* 人力资源 */
	.rlzy-title {
		margin: 0px 0 30px 0;
	}

	.rlzy-list {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-end;
		margin-bottom: 40px;
	}

	.rlzy-item {
		width: 335px;
		margin-bottom: 16px;
		padding: 10px 20px;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: rgba(21, 154, 255, 0.1);
	}

	.rlzy-item span {
		font-size: 14px;
		font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
		font-weight: 400;
		color: #d0deee;
	}

	.rlzy-item .rlzy-l {
		display: flex;
		align-items: center;
	}

	.rlzy-item .rlzy-l img {
		display: block;
		margin-right: 10px;
		width: 42px;
		height: 42px;
	}

	.rlzy-item .rlzy-l div {
		width: 2px;
		height: 20px;
		background: #66e1df;
		margin-right: 10px;
	}

	.rlzy-item p {
		font-size: 20px;
		font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
		font-weight: bold;
		color: #ffffff;
	}

	/* 电力资源 */

	.dlzy-top {
		display: flex;
		justify-content: space-between;
	}

	.dlzy-item {
		display: flex;
		align-items: center;
	}

	.dlzy-item span {
		font-size: 12px;
		color: #d0deee;
	}

	.dlzy-item p {
		font-size: 24px;
		color: #fff;
	}

	#line {
		width: 380px;
		height: 300px;
		padding-left: 30px;
	}

	/* 地磅实时数据 */
	.con-left .title {
		/* display: block; */
		height: 40px;
		background: url(../../assets/images/title\(1\).png) no-repeat;
		color: #fff;
		font-size: 20px;
		font-weight: bold;
		padding: 0 0 20px 50px;
		margin-left: 1%;
		display: flex;
		align-items: center;
		box-sizing: border-box;
	}

	.con-left .title span {
		display: block;
		margin-bottom: 10px;
	}

	.con-left .title img {
		display: block;
		width: 12px;
		height: 12px;
		margin-left: 10px;
	}

	/* dibang */
	.con-left-buttom {
		padding-left: 36px;
		flex: 1;
		box-sizing: border-box;

		.clb_tab {
			margin-bottom: 20px;
			display: flex;
			align-items: center;

			.son {
				padding: 0 14px;
				height: 40px;
				display: block;
				border-top: 2px solid #6c8097;
				background: linear-gradient(180deg, #2d3f57 0%, rgba(108, 128, 151, 0) 100%);
				font-size: 14px;
				font-weight: bold;
				color: #fff;
				line-height: 32px;
				text-align: center;
				margin-right: 10px;

				&.on {
					border-top: 2px solid #159aff;
					background: linear-gradient(180deg, #094983 0%, rgba(21, 154, 255, 0) 100%);
				}
			}
		}
	}

	.db-t {
		width: 100%;
		color: #d0deee;
		text-align: center;
		display: flex;
		align-items: center;
	}

	.db-t-h {

		flex: 90px;
		font-size: 14px;
	}

	.db-t-h:nth-last-child(1),
	.db-t-h:nth-child(6),
	.db-t-h:nth-last-child(2) {
		flex: 160px;
	}

	.db-t-h:nth-child(1) {
		flex: 60px;
	}

	.db-b {
		font-size: 13px;
		color: #fff;
		padding: 4px 0;
	}

	.db-b div {
		display: flex;
		align-items: center;
	}

	.db-b div span {
		flex: 90px;
		display: block;
		text-align: center;
	}

	.db-b div span:nth-child(1) {
		flex: 60px;
	}


	.db-b div span:nth-last-child(1),
	.db-b div span:nth-child(6),
	.db-b div span:nth-last-child(2) {
		flex: 160px;
	}

	.db-b div span input {
		width: 70px;
		border: none;
		outline: none;
		margin: 0 4px 0 0;
		background: #102b42;
		color: #fff;
		padding-left: 2px;
	}

	.con-tab {
		display: flex;
		color: #fff;
		font-size: 22px;
		margin-bottom: 10px;
	}

	.con-tab span {
		margin-right: 40px;
	}

	.con-tab .onS {
		color: #66e1df;
	}

	.con-jiankong {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.sty_0 {
		width: 48vw;
		height: calc(48vw * 2 /5);
	}

	.sty_1 {
		width: 25vw;
		height: calc(25vw * 3 / 4);
	}

	.sty_2 {
		width: 17vw;
		height: calc(17vw * 2 / 3);
	}

	.sty_3 {
		width: 12.5vw;
		height: calc(12.6vw * 12 / 13);
	}

	.sty_4 {
		width: 12.5vw;
		height: calc(12.6vw * 2 / 3);
	}

	/*  */
	.jrcpl-item .jrcpl-item-con .kuai1 {
		background-color: #d0deee;
	}

	.jrcpl-item .jrcpl-item-con .kuai2 {
		background-color: #66e1df;
	}

	.video-tools {
		display: none;
	}

	/* 分页 */
	.block1 {
		margin-top: -6px;
		color: #fff;
		display: flex;
		align-items: center;
		z-index: 90;
	}

	/* 选中 */
	.block1 /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
		background: linear-gradient(180deg, #159aff 0%, #66e1df 100%);
		color: #fff;
		border-radius: 4px 4px 4px 4px;
		border: none;
		box-sizing: border-box;
	}

	/* 未选中 */
	.block1 /deep/ .el-pagination.is-background .el-pager li:not(.disabled) {
		background-color: #011633;
		color: #fff;
		border-radius: 4px 4px 4px 4px;
		border: 1px solid #fff;
		box-sizing: border-box;
	}

	.block1 /deep/ .el-pager li.active+li {
		border: 1px solid #fff;
		/*  */
	}

	/* 上一页  下一页 */
	.block1 /deep/ .el-pagination.is-background .btn-next,
	.block1 /deep/ .block.is-background .btn-prev {
		background-color: #011633;
		color: #fff;
		border-radius: 4px 4px 4px 4px;
		border: 1px solid #fff;
		box-sizing: border-box;
	}

	/* 跳转 */
	.block1 /deep/ .el-pagination__jump {
		margin-left: 24px;
		color: #fff;
	}

	.block1 /deep/ .el-pagination button:disabled {
		background-color: #011633;
		border: 1px solid #d0deee;
		box-sizing: border-box;
		border-radius: 4px 4px 4px 4px;
	}

	.block1 /deep/ .el-pagination .btn-next,
	.block1 /deep/ .el-pagination .btn-prev {
		background-color: #011633;
		border: 1px solid #d0deee;
		color: #fff;
		border-radius: 4px 4px 4px 4px;
	}

	.block1 /deep/ .el-pager li {
		margin: 0 4px;
		background-color: #011633;
		border: 1px solid #d0deee;
		border-radius: 4px 4px 4px 4px;
		box-sizing: border-box;
		color: #fff;
	}

	.block1 /deep/ .el-pager li.active {
		background: linear-gradient(180deg, #159aff 0%, #66e1df 100%);
		color: #fff;
		border-radius: 4px 4px 4px 4px;
		border: none;
		cursor: default;
	}

	/* 账号弹框 */
	.zh-tk {
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.5);
		width: 100%;
		height: 100%;
		z-index: 92;
	}

	.zh-bg {
		position: fixed;
		top: 0;
		width: 100%;
		height: 100%;
	}

	.zh-box {
		width: 65%;
		height: 720px;
		background: #021328;
		opacity: 1;
		border: 1px solid;
		box-sizing: border-box;
		border-image: linear-gradient(153deg, rgba(102, 225, 223, 1), rgba(102, 225, 223, 0)) 2 2;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.zh-close {
		position: absolute;
		right: -10px;
		top: -10px;
		width: 26px;
		height: 26px;
		z-index: 111;
	}

	.zh-con {
		height: 100%;
		display: flex;
		color: #fff;
	}

	.zh-menu {
		width: 25%;
		height: 100%;
		padding: 30px 24px;
		box-sizing: border-box;
		background: #021328;
	}

	.zh-menu-item {
		width: 215px;
		height: 60px;
		color: #ffffff;
		font-size: 20px;
		cursor: pointer;
		position: relative;
	}

	.zh-menu-item span {
		position: absolute;
		top: 50%;
		left: 20px;
		transform: translateY(-50%);
	}

	.zh-xuan {
		color: #66e1df;
	}

	.zh-xuan-tu {
		position: absolute;
	}

	/* 账户信息 */
	.zh-r {
		flex: 1;
		background: #071f34;
		padding: 60px;
	}

	.zh-name,
	.zh-password {
		font-size: 18px;
		display: flex;
		align-items: center;
		margin-bottom: 24px;
	}

	.zh-name-l {
		width: 60px;
		text-align: right;
	}

	.zh-name-c {
		margin: 0 30px 0 16px;
		width: 267px;
		height: 44px;
		font-size: 16px;
		position: relative;
	}

	.zh-name-c img {
		position: absolute;
	}

	.zh-name-c span {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.zh-edit {
		font-size: 16px;
		color: #66e1df;
	}

	/* 分配子账号 */
	.zh-fp {
		width: 100%;
		height: 100%;
	}

	.zh-fp-titlr {
		width: 100%;
		padding: 20px 30px;
		margin-bottom: 10px;
		box-sizing: border-box;
		background: #071f34;
		font-size: 20px;
		color: #fff;
	}

	.zh-fp-b {
		width: 100%;
		padding: 30px;
		font-size: 16px;
		box-sizing: border-box;
		background: #071f34;
	}

	.zh-fp-t {
		margin-bottom: 40px;
		display: flex;
	}

	.zh-fp-t-item {
		display: flex;
		align-items: center;
		box-sizing: border-box;
	}

	.zh-fp-t-item span {
		display: block;
		width: 48px;
		text-align: right;
		margin-right: 16px;
	}

	.zh-fp-t-item input {
		width: 180px;
		height: 34px;
		box-sizing: border-box;
		padding-left: 2em;
		outline: none;
		border: none;
		color: #fff;
		background: #102b42;
	}

	.zh-fp-t .zhaddbtn {
		width: 99px;
		height: 34px;
		display: block;
		margin-left: 60px;
		box-sizing: border-box;
		font-size: 16px;
		color: #071f34;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		background: #66e1df;
	}

	/* 子账号列表 */
	.zh-table-title {
		display: flex;
		font-size: 18px;
		margin-bottom: 20px;
	}

	.zh-table-title span:nth-child(1),
	.zh-table-title span:nth-child(2) {
		width: 40%;
	}

	.zh-table-title span:nth-child(3) {
		flex: 1;
	}

	.zh-table-box {
		height: 464px;
		padding-bottom: 20px;
		box-sizing: border-box;
		overflow: auto;
		font-size: 18px;
	}

	.zh-table-item {
		padding: 12px 20px;
		margin-bottom: 14px;
		background: #071b30;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.zh-table-item>span {
		width: 40%;
	}

	.zh-tab-cz {
		color: #66e1df;
		display: flex;
		align-items: center;
		flex: 1;
		justify-content: flex-start;
	}

	.zh-tab-cz img {
		width: 20px;
		height: 20px;
		margin-right: 6px;
	}

	.zh-tab-cz-on {
		color: #dd442b;
	}

	/*  */
	::-webkit-scrollbar {
		width: 3px;
		background-color: #2087b9;
	}

	/* 滚动条上的按钮 (上下箭头). */
	::-webkit-scrollbar-button {
		width: 3px;
		height: 0;
		background-color: #2087b9;
		opacity: 0.2;
	}

	::-webkit-scrollbar-thumb {
		width: 3px;
		/* 上下两头给个圆角 */
		background-color: #2087b9;
		border-radius: 10px;
		/* 还可以给它加个阴影 */
		/* box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);  */
	}

	/* 滚动条轨道 */
	::-webkit-scrollbar-track {
		/* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
		border-radius: 10px;
		background-color: #071f34;
	}

	/* 当同时有垂直滚动条和水平滚动条时交汇的部分. */
	::-webkit-scrollbar-corner {
		background: steelblue;
		border-radius: 50% 50%;
	}

	#pieone,
	#pieone2 {
		width: 200px;
		height: 200px;
	}

	#pietwo {
		width: 200px;
		height: 200px;
	}

	#piethree {
		width: 200px;
		height: 200px;
	}

	.renedit {
		color: #fff;
		margin: 0 auto;
		text-align: center;
		font-size: 40px;
		margin-top: 30px;
	}

	.ren-item {
		color: #fff;
		margin-bottom: 20px;
	}

	.ren-item label {
		margin-right: 10px;
		display: inline-block;
		width: 80px;
		height: 30px;
		line-height: 30px;
		vertical-align: middle;
		text-align: justify;
		text-align-last: justify;
	}

	label::after {
		content: " ";
		display: inline-block;
		width: 100%
	}

	.ren-item input {
		outline: none;
		padding: 10px 2em;
		background: #102b42;
		border: none;
		color: #fff;
	}

	.renren {
		position: relative;
		width: 40vw;
		height: 80%;
	}

	.ren-box {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.ren-btn {
		position: absolute;
		bottom: 0;
		width: 100%;
		padding: 30px;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}

	.ren-btn div {
		color: #66e1df;
		font-size: 24px;
	}
</style>